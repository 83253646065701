const dataFooter = {
  titelTimeWork: "Время работы:",
  timeWork: "Понедельник — Воскресенье: с 11:00 до 01:00",
  titelAdress: "Адрес:",
  adress: "Курорт Красная Поляна, Поляна 960, ул. Созвездий, 5а",
  titelPhone: "Телефон:",
  phone: "+7 (938) 446-68-88",
};

export { dataFooter };
