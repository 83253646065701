import { useState, useEffect } from "react";

import { Header } from "../../Entities/Header/Header";
import { Philosophy } from "../../Entities/BlockPhilosophy/Philosophy";
import { Atmosphere } from "../../Entities/BlockAtmosphere/Atmosphere";
import { AboutFood } from "../../Entities/BlockAboutFood/AboutFood";
import { MakingBeer } from "../../Entities/BlockMakingBeer/MakingBeer";
import { ByTryingBeer } from "../../Entities/BlockByTryingBeer/ByTryingBeer";
import { TakeItWithYou } from "../../Entities/BlockTakeItWithYou/BlockTakeItWithYou";
import { Footer } from "../../Entities/Footer/Footer";

// OpenPub
import { OpenPub } from "../../Widgets/OpenPub/OpenPub";

//popup
import { RentTab } from "../../Widgets/Popup/rentTab/RentTab";
//Modal
import { ModalBar } from "../../Widgets/Modal/ModalBar";
//burger
import { Burger } from "../../Widgets/Burger/Burger";

//styles
import styles from "../../../styles/layout/Home/Home.module.scss";

const Home = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBurger, setOpenBurger] = useState(false);
  const [startSliderState, setStartSliderState] = useState(0);

  useEffect(() => {
    openPopup || openModal || openBurger
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [openPopup, openModal, openBurger]);

  return (
    <div className={styles.wrapper}>
      {openPopup ? <RentTab setOpenPopup={setOpenPopup} /> : <></>}
      {openModal ? (
        <ModalBar
          setOpenModal={setOpenModal}
          startSliderState={startSliderState}
        />
      ) : (
        <></>
      )}
      {openBurger ? (
        <Burger setOpenBurger={setOpenBurger} setOpenPopup={setOpenPopup} />
      ) : (
        <></>
      )}
      <div className={styles.photoHeader}>
        <img
          sizen={
            "(max-width : 360px) 360px, (max-width : 390px) 390px, (max-width : 598px) 390px, (max-width : 840px) 814px, (max-width : 1440px) 1200px, (max-width : 1600px) 1600px, 1920px"
          }
          srcset="
          /img/Header/mixin/headerNew_1920.jpg 360w,
          /img/Header/mixin/headerNew_1920.jpg 390w,
          /img/Header/mixin/headerNew_1920.jpg 814w,
          /img/Header/mixin/headerNew_1920.jpg 1200w,
          /img/Header/mixin/headerNew_1920.jpg 1600w, 
          /img/Header/mixin/headerNew_1920.jpg 1920w 
          "
          decoding="async"
          // width={"100%"}
          // height={"100%"}
          // src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/image_989.png"
          src="/img/Header/mixin/headerNew_1920.jpg"
          // src="/img/Header/OpenPub.png"
        />
      </div>
      <div className={styles.container}>
        {/* <OpenPub /> */}
        <Header setOpenPopup={setOpenPopup} setOpenBurger={setOpenBurger} />
        <div className={styles.contentHomeGap}>
          <Philosophy />
          <Atmosphere
            setOpenPopup={setOpenPopup}
            setOpenModal={setOpenModal}
            setStartSliderState={setStartSliderState}
          />
          <AboutFood />
          <MakingBeer />
          <ByTryingBeer />
          <TakeItWithYou />
          <Footer />
        </div>
      </div>
    </div>
  );
};

// /img/Header/mixin/header_360.png 360w,
//           /img/Header/mixin/header_390.png 390w,
//           /img/Header/mixin/header_840.png 814w,
//           /img/Header/mixin/header_1160.png 1200w,
//           /img/Header/mixin/header_1600.png 1600w,

export { Home };
