import styles from "../../../styles/layout/BlockAtmosphere/Atmosphere.module.scss";
import { dataBar } from "./dataAtmosphere";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
import { useState } from "react";

const Atmosphere = ({ setOpenModal, setOpenPopup, setStartSliderState }) => {
  // const [openMotive, setOpenMotive] = useState(false);

  const switchIdModalSlide = (id) => {
    const idSwitch = {
      0: 0,
      1: 2,
      2: 9,
      3: 13,
    };
    setStartSliderState(() => idSwitch?.[id]);
    setOpenModal(() => true);
  };
  return (
    <section id="inter" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h2 className={styles.textTitel}>ПРОНИКНИТЕСЬ АТМОСФЕРОЙ</h2>
      </div>
      <div className={styles.blockDiscript}>
        <p className={styles.textDisript}>
          Птицы захмелели ‒ это отдых на любой вкус
        </p>
        <button
          onClick={() => {
            setOpenPopup(() => true);
          }}
          className={styles.buttonRentTab}
        >
          ЗАБРОНИРОВАТЬ СТОЛ
        </button>
      </div>
      <div className={styles.blockVarintBar}>
        {dataBar.slice(0, 4).map((elem, index) => (
          <div
            key={index}
            onClick={() => switchIdModalSlide(index)}
            className={styles.contentVariantBar}
          >
            <img className={styles.imgVariantBar} src={elem?.photo} />
            <div className={styles.blockTextVarintBar}>
              <div className={styles.blockAbsText}>
                <h2 className={styles.textContentVarintBar}>
                  {elem?.titel.toLocaleUpperCase()}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export { Atmosphere };
