import styles from "../../../../styles/layout/Widgets/popup/RentTab.module.scss";

const RentTab = ({ setOpenPopup }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.blockBirdPopupLeft}>
          <img src={"/img/Popup/Group 1171275863.png"} />
        </div>
        <div className={styles.blockBirdPopupRight}>
          <img src={"/img/Popup/Group 1171275863.png"} />
        </div>

        <h1>{"Забронировать стол".toLocaleUpperCase()}</h1>
        <h2>{"Вы можете по телефону:".toLocaleUpperCase()}</h2>
        {/* <h3>{"+7 862 552-03-00".toLocaleUpperCase()}</h3> */}
        <span>
          <a href="tel:++79384466888">
            {"+7 (938) 446-68-88".toLocaleUpperCase()}
          </a>
        </span>
        <button
          onClick={() => setOpenPopup(() => false)}
          className={styles.buttonClose}
        >
          {"Закрыть".toLocaleUpperCase()}
        </button>
      </div>
    </div>
  );
};

export { RentTab };
