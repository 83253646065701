const dataBarAll = [
  //bar
  {
    photo: "/img/Atmosphere/AllPhoto/01_bar/bar-1.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/01_bar/bar-2.jpg",
  },
  // 02 interior start index 2
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-1.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-2.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-3.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-4.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-6.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-7.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/02_interior/interior-9.jpg",
  },
  // 03 outdoor start index 9
  {
    photo: "/img/Atmosphere/AllPhoto/03_outdoor/outdoor-1.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/03_outdoor/outdoor-2.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/03_outdoor/outdoor-3.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/03_outdoor/outdoor-4.jpg",
  },
  // 04 shop start index 13
  {
    photo: "/img/Atmosphere/AllPhoto/04_shop/shop-1.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/04_shop/shop-2.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/04_shop/shop-3.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/04_shop/shop-4.jpg",
  },
  {
    photo: "/img/Atmosphere/AllPhoto/04_shop/shop-6.jpg",
  },
];

export { dataBarAll };
