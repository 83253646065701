const dataBar = [
  {
    titel: "Барная посадка",
    photo: "/img/Atmosphere/barS.jpg",
  },
  {
    titel: "Ресторанная посадка",
    photo: "/img/Atmosphere/restP.jpg",
  },
  {
    titel: "Сезонная видовая терраса",
    photo: "/img/Atmosphere/seasonT.jpg",
  },
  {
    titel: "Фирменный магазин",
    photo: "/img/Atmosphere/firmM.jpg",
  },
  {
    titel: "Быстрая уличная линия",
    photo: "/img/Atmosphere/image 980.png",
  },

  {
    titel: "Быстрая уличная линия",
    photo: "/img/Atmosphere/image 993.png",
  },
  {
    titel: "Быстрая уличная линия",
    photo: "/img/Atmosphere/image 994.png",
  },
];

export { dataBar };
